/* eslint-disable @next/next/no-img-element */
import type { AppProps } from 'next/app'
import Script from 'next/script'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import '../app/styles/globals.sass'

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<>
			<Script id="meta-pixel">
				{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', 3709104566069856);
        fbq('track', 'PageView');
      `}
			</Script>

			<QueryClientProvider client={queryClient}>
				<Component {...pageProps} />
			</QueryClientProvider>
			<noscript>
				<img
					alt="fbpixel"
					height="1"
					width="1"
					style={{ display: 'none' }}
					src="https://www.facebook.com/tr?id=3709104566069856&ev=PageView&noscript=1"
				/>
			</noscript>
		</>
	)
}
export default MyApp
